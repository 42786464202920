import React from "react";

import { Button } from "react-bootstrap";

import "./primary-button.styles.scss";
import Loader from "../loader/loader.component";

const PrimaryButton = ({
  handleClick,
  rounded,
  noRadius,
  children,
  small,
  cancel,
  disabled,
  gray,
  skipCheck,
  dataTest,
  loading,
  primaryShade,
  redOrange,
  center,
  step,
  disbaledColor,
  component,
  containerClass,
  utility,
  ...rest
}) => {
  return (
    <div
      className={`primary-button-component ${
        containerClass ? containerClass : ""
      } ${rounded ? "rounded" : ""} ${disbaledColor ? "disbaledColor" : ""} ${
        utility ? "utility" : ""
      } ${center ? "center" : ""} `}>
      <Button
        className={`${skipCheck ? "skip" : ""}   ${gray ? "gray" : ""}${
          cancel ? "cancel" : ""
        } ${small ? "small" : ""} ${primaryShade ? "primaryShade" : ""}${
          redOrange ? "redOrange" : ""
        } ${noRadius ? "no-radius" : ""}`}
        disabled={loading || disabled}
        onClick={handleClick}
        data-test={dataTest}
        {...rest}>
        <div className="children">
          {component ? (
            React.cloneElement(component)
          ) : loading ? (
            <Loader size="20px" borderSize={"2px"} />
          ) : (
            ""
          )}
          {loading && step ? step.message : children}
        </div>
      </Button>
    </div>
  );
};

export default PrimaryButton;
