const INITIAL_STATE = {
  profiles: {},
  singleProfile: {},
};

const socialMediaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_PROFILES":
      return {
        ...state,
        profiles: action.payload,
      };

    case "SET_INDIVIDUAL_PROFILE":
      return {
        ...state,
        singleProfile: action.payload,
      };

    default:
      return state;
  }
};

export default socialMediaReducer;
