import React from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router";
import { defaultRedirect } from "../../pages/index-page";

function GuardedRoute({ children, roles }) {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.base);
  const { role: currentRole } = user;
  console.log("currentRole", currentRole, roles.includes(currentRole));
  if (roles.includes(currentRole)) {
    return children ? children : <Outlet />;
  } else {
    console.log(":asd");
  }

  defaultRedirect(user, navigate);
  return <div>You are not authorized to view this page</div>;
}

export default GuardedRoute;
