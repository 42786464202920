import React, { useEffect, useTransition } from "react";
import firebase, { addUserNameEmailOAuth } from "./firebase/firebase.utils";

import { changeUser, updateUserUploadLoading } from "./redux/base/base.actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import {
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
// import Dashboard from "./components/Dashboard";
import "./App.scss";
import Loader from "./components/loader/loader.component";
import IndexPage, { defaultRedirect } from "./pages/index-page";
import { AllRoutes } from "./utils/routes";
function NotFoundPage() {
  const location = useLocation();
  const user = useSelector((state) => state.base.user);
  const navigate = useNavigate();
  useEffect(() => {
    const pathExists = AllRoutes.some(
      (route) => route.path === location.pathname
    );
    if (!pathExists) {
      defaultRedirect(user, navigate);
    }
  }, [location.pathname, user, navigate]);
  return (
    <div>
      <h1>404 - Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
}

const Router = createBrowserRouter([
  {
    element: <IndexPage />,
    loader: () => {
      console.log("We can fetch our website basic data over here!");
      return null;
    },
    path: "/",
    children: AllRoutes,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.base.user);
  const [isPending, startTransition] = useTransition();
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (userAuth) => {
      let userSnapshot;
      if (userAuth) {
        dispatch(updateUserUploadLoading(false));

        const userRef = await addUserNameEmailOAuth(userAuth);
        dispatch(updateUserUploadLoading(true));
        userSnapshot = userRef.onSnapshot(async (snapShot) => {
          const data = snapShot.data() ? snapShot.data() : {};
          // Wrap the state update in startTransition
          startTransition(() => {
            dispatch(
              changeUser({
                uid: userAuth.uid,
                verified: userAuth.emailVerified,
                authLoaded: true,
                authenticated: true,
                providerData: userAuth.providerData,
                photoUrl: userAuth.photoURL,
                loggedIn: true,
                ...data,
              })
            );
          });
        });
      } else {
        // Wrap the state update in startTransition for the else case as well
        startTransition(() => {
          dispatch(
            changeUser({
              uid: 0,
              email: "",
              displayName: "",
              loggedIn: false,
              authLoaded: true,
              authenticated: false,
            })
          );
        });
      }
      return () => userSnapshot && userSnapshot();
    });
  }, [dispatch]);

  if (!user || !user.authLoaded || isPending) return <Loader zIndex={9999} />;
  else {
    return <RouterProvider router={Router} />;
  }
}

export default App;
