const INITIAL_STATE = {
  donations: [],
  user: {
    uid: 0,
    email: "",
    displayName: "",
    loggedIn: false,
  },
  allowedEmails: [],
  emailStats: [],
  uniqueDonorsStats: [],
  uniqueDonorsStatsByTenant: null,
  emailsSentStatsByTenant: null,
  fileToUpload: null,
  uploadingFile: false,
  uploadedFileURL: null,
  uploadStatus: "",
  fileList: [],
  ayrshareProfiles: [],
  jwt_URL: "",
  monthlyDonationStatsByTenant: {},
  userUploadLoading: true,
};

const baseReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_DONATIONS":
      return {
        ...state,
        donations: action.payload,
      };
    case "SET_FILE_LIST":
      return {
        ...state,
        fileList: action.payload,
      };
    case "SET_AYRSHARE_PROFILES":
      return {
        ...state,
        ayrshareProfiles: action.payload,
      };
    case "SET_AYRSHARE_JWT_URL":
      return {
        ...state,
        jwt_URL: action.payload,
      };
    case "SET_FILE_TO_UPLOAD":
      return {
        ...state,
        fileToUpload: action.payload,
        uploadingFile: true,
      };

    case "UPLOAD_FILE_COMPLETE":
      return {
        ...state,
        fileToUpload: null,
        uploadingFile: false,
        uploadedFileURL: action.payload,
      };
    case "UPLOAD_FILE_ERROR":
      return {
        ...state,
        fileToUpload: null,
        uploadingFile: false,
        uploadStatus: "Error in uploading File",
      };
    case "SET_ALLOWED_EMAILS":
      return {
        ...state,
        allowedEmails: action.payload,
      };
    case "SET_EMAILS_STATS":
      return {
        ...state,
        emailStats: action.payload,
      };
    case "SET_UNIQUE_DONOR_STATS":
      return {
        ...state,
        uniqueDonorsStats: action.payload,
      };
    case "SET_UNIQUE_DONOR_STATS_BY_TENANT":
      return {
        ...state,
        uniqueDonorsStatsByTenant: action.payload,
      };
    case "SET_EMAIL_STATS_BY_TENANT":
      return {
        ...state,
        emailsSentStatsByTenant: action.payload,
      };
    case "SET_MONTHLY_DONATAION_STATS_BY_TENANT":
      return {
        ...state,
        monthlyDonationStatsByTenant: action.payload,
      };
    case "UPDATE_USER_UPLOAD_LOADING":
      return {
        ...state,
        userUploadLoading: action.payload,
      };
    case "SIGN_OUT":
      return {
        INITIAL_STATE,
      };
    case "CHANGE_USER":
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default baseReducer;

//all the logic of what to update goes here
