import React from "react";

import "./loader.styles.css";

const Loader = ({ zIndex }) => {
  return (
    <div className="loading" style={zIndex && { zIndex }}>
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
