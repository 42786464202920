import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import logger from "redux-logger";

import rootReducer from "./root-reducer";
import { config } from "../firebase/firebase.utils";
import {
  createFirestoreInstance,
  reduxFirestore,
  getFirestore,
} from "redux-firestore";
import { getFirebase } from "react-redux-firebase";

import firebase from "../firebase/firebase.utils";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      thunk.withExtraArgument({ getFirebase, getFirestore }),
      logger
    ),
    reduxFirestore(firebase, config)
  )
);

// const store = createStore(rootReducer, applyMiddleware(...middlewares));

export const rrfProps = {
  firebase,
  config: config,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

export default store;
