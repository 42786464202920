import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { signInWithGoogle, signOut } from "../../redux/base/base.actions";
import { useNavigate } from "react-router-dom";
import "./menu.styles.scss";
import PrimaryButton from "../primary-button/primary-button.component";

export default function Menu() {
  const user = useSelector((state) => state.base.user);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  return (
    <div className="admin-sidebar">
      <div className="title">Infaque Stats</div>
      {user.loggedIn && <p>Hello, {user.displayName}</p>}
      {user.loggedIn && <p>{user.email}</p>}
      <div className="buttons">
        {user.loggedIn && user.role === "admin" && (
          <PrimaryButton
            className="button"
            handleClick={() => navigate("/admin-dashboard")}>
            Dashboard
          </PrimaryButton>
        )}

        {user.loggedIn && user.role === "admin" && (
          <PrimaryButton
            handleClick={() => navigate("/tenant-configurations")}
            className="button">
            Configure a Tenant
          </PrimaryButton>
        )}
        {user.loggedIn && user.role === "admin" && (
          <PrimaryButton
            handleClick={() => navigate("/tenant-configurations/test")}
            className="button">
            Configure a Test Tenant
          </PrimaryButton>
        )}
        {user.loggedIn && user.role === "admin" && (
          <PrimaryButton
            handleClick={() => navigate("/prompts")}
            className="button">
            Configure Prompts
          </PrimaryButton>
        )}
        {user.loggedIn && user.role === "admin" && (
          <PrimaryButton
            className="button"
            handleClick={() => navigate("create-checkout-sessions")}>
            Create Payment URL
          </PrimaryButton>
        )}
        {user.loggedIn && user.role === "admin" && (
          <PrimaryButton
            className="button"
            handleClick={() => navigate("addusers")}>
            Add Users
          </PrimaryButton>
        )}
        {user.loggedIn && user.role === "admin" && (
          <PrimaryButton
            className="button"
            handleClick={() => navigate("socialmedia")}>
            Social Media
          </PrimaryButton>
        )}

        {!user.loggedIn && (
          <PrimaryButton
            className="button"
            gray
            handleClick={dispatch(signInWithGoogle)}>
            Sign In with Google
          </PrimaryButton>
        )}
        {user.loggedIn && (
          <PrimaryButton className="button" onClick={dispatch(signOut)}>
            Sign Out
          </PrimaryButton>
        )}
      </div>
    </div>
  );
}
